import Cookies from 'js-cookie'
import config from '../config'
import * as tus from 'tus-js-client'
import { request } from './axios'
/**
 * @param {*} key
 * @param {*} value
 * @param {*} storage sessionStorage || localStorage
 */
export const setStore = (key, value, storage = 'sessionStorage') => {
    if (!key) return
    const time = new Date().getTime()
    window[storage].setItem(key, JSON.stringify({ value, time }))
}

/**
 *
 * @param {*} key
 * @param {*} exp 过期时间
 * @param {*} storage
 */
export const getStore = (key, exp, storage = 'sessionStorage') => {
    const value = window[storage].getItem(key)
    if (!value) return ''
    const valurObj = JSON.parse(value)
    if (exp) {
        if (new Date().getTime() - valurObj.time > exp) {
            window[storage].removeItem(key)
            return ''
        } else {
            return valurObj.value
        }
    } else {
        return valurObj.value
    }
}

export const removeStore = (name, storage = 'sessionStorage') => {
    if (!name) return
    window[storage].removeItem(name)
}

export const clearAllStore = (storage = 'sessionStorage') => {
    window[storage].clear()
}

/**
 *
 * @param {*} key
 * @param {*} value
 * @param {*} expires 过期时间单位天
 */
export const setCookies = (key, value, expires = 1) => {
    if (!key) return
    Cookies.set(key, value, {expires,})
}

export const getCookies = (key) => {
    const value = Cookies.get(key)
    if (!value) return false
    return value
}

export const clearAllCookie = () => {
    const temps = Cookies.get()
    for (const key in temps) {
        Cookies.remove(key)
    }
}

//token存取
export const getToken = () => {
    return getCookies(config.tokenKey)
}

export const setToken = (value,time) => {
    setCookies(config.tokenKey, value, time)
}

//username存取
export const getUsername = () => {
    return getCookies(config.username)
}

export const setUsername = (value) => {
    setCookies(config.username, value, config.cookieExpires)
}

    
//password存取
export const getPassword = () => {
    return getCookies(config.password)
}

export const setPassword= (value) => {
    setCookies(config.password, value, config.cookieExpires)
}


export const forEach = (arr, fn) => {
    if (!arr.length || !fn) return
    let i = -1
    let len = arr.length
    while (++i < len) {
        let item = arr[i]
        fn(item, i, arr)
    }
}

export const isPC = () => {
    const userAgentInfo = navigator.userAgent
    const Agents = [
        'Android',
        'iPhone',
        'SymbianOS',
        'Windows Phone',
        'iPad',
        'iPod',
    ]
    let flag = true
    for (let i = 0; i < Agents.length; i++) {
        if (userAgentInfo.indexOf(Agents[i]) > 0) {
            flag = false
            break
        }
    }
    return flag
}

/**
 * 动态引入js
 * @param {*} src
 */
export const injectScript = (src) => {
    const scriptDom = document.createElement('script')
    scriptDom.type = 'text/javascript'
    scriptDom.async = true
    scriptDom.src = src
    document.getElementsByTagName('head')[0].appendChild(scriptDom)
}

/**
 * 是否字符串
 */
export const isString = (o) => {
    return Object.prototype.toString.call(o).slice(8, -1) === 'String'
}

/**
 * 是否数字
 */
export const isNumber = (o) => {
    return Object.prototype.toString.call(o).slice(8, -1) === 'Number'
}

/**
 * 是否为null
 */
export const isNull = (o) => {
    return Object.prototype.toString.call(o).slice(8, -1) === 'Null'
}

/**
 * 是否undefined
 */
export const isUndefined = (o) => {
    return Object.prototype.toString.call(o).slice(8, -1) === 'Undefined'
}

/**
 * 是否对象
 */
export const isObj = (o) => {
    return Object.prototype.toString.call(o).slice(8, -1) === 'Object'
}

/**
 * 是否时间
 */
export const isDate = (o) => {
    return Object.prototype.toString.call(o).slice(8, -1) === 'Date'
}
/**
 * URL地址
 * @param {*} s
 */
 export function isURL (s) {
    return /^http[s]?:\/\/.*/.test(s)
  }
  
/**
 * 判断是否为空
 */
 export function validatenull (val) {
    if (typeof val === 'boolean') {
      return false
    }
    if (typeof val === 'number') {
      return false
    }
    if (val instanceof Array) {
      if (val.length == 0) return true
    } else if (val instanceof Object) {
      if (JSON.stringify(val) === '{}') return true
    } else {
      if (val == 'null' || val == null || val == 'undefined' || val == undefined || val == '') return true
      return false
    }
    return false
  }

/**
 * @description 判断两个对象是否相等，这两个对象的值只能是数字或字符串
 */
export const objEqual = (obj1, obj2) => {
    const keysArr1 = Object.keys(obj1)
    const keysArr2 = Object.keys(obj2)
    if (keysArr1.length !== keysArr2.length) return false
    else if (keysArr1.length === 0 && keysArr2.length === 0) return true
    /* eslint-disable-next-line */ else
        return !keysArr1.some((key) => obj1[key] !== obj2[key])
}

export const deepClone = (obj) => {
    if (obj !== 'object') {
        return obj
    }
    if (obj.constructor === Date) {
        return new Date(obj)
    }
    if (obj.construtor === RegExp) {
        return new RegExp(obj)
    }
    const temp = obj.constructor()
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            temp[key] =
                typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key]
        }
    }
    return temp
}

/**
 *
 * @param {*} bytes // 注意单位
 * @param {*} decimals
 */
export const sizeFormat = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const dm = decimals || 2
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const downloadFile = (filename, text) => {
    let link = document.createElement('a')
    link.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + JSON.stringify(text)
    )
    link.setAttribute('download', filename)
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

/**
 * 下载二进制文件
 * @param {*} url
 */
export const getBlob = (url,filename) => {
   return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.responseType = 'blob'
        xhr.setRequestHeader("token",getToken())
        xhr.setRequestHeader("Content-Type","application/vnd.ms-excel")
        // xhr.setRequestHeader= {
        //    Accept: '*',
        //     ' Content-Type': 'application/zip',
        // }
        xhr.onload = () => {
           if (xhr.status >= 200 && xhr.status < 400) {
               resolve(xhr.response)
           } else {
                reject(xhr.response)
            }
        }
        xhr.send()
    }).then(res=>{
        // console.log(res,"pp55");
        const blob = new Blob([res],{type:'application/vnd.ms-excel'})
        if ('download' in document.createElement('a')) {
          const elink = document.createElement('a')
          elink.download = filename
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob,filename)
        }
    })
}

export const export2Excel=(columns,list,name)=>{
    require.ensure([], () => {
        const { export_json_to_excel } = require('../excel/Export2Excel');
        let tHeader = []
        let filterVal = []
        columns.forEach(item =>{
             tHeader.push(item.title)
            filterVal.push(item.key)
        })
        const data = list.map(v => filterVal.map(j => v[j]))
        export_json_to_excel(tHeader, data, name);
    })
}

/**
 * 根据url地址下载
 * @param {*} url
 */
export const download = (url) => {
    const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1
    const isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1
    if (isChrome || isSafari) {
        var link = document.createElement('a')
        link.href = url
        if (link.download !== undefined) {
            link.download = url
        }
        if (document.createEvent) {
            var e = document.createEvent('MouseEvents')
            e.initEvent('click', true, true)
            link.dispatchEvent(e)
            return true
        }
    }
    if (url.indexOf('?') === -1) {
        url += '?download'
    }
    window.open(url, '_self')
    return true
}
export const downloadIamge =(imgsrc,name) =>{   //下载图片地址和图片名
    var image = new Image();
    // 解决跨域 Canvas 污染问题
    image.setAttribute("crossOrigin", "anonymous");
    image.onload = function() {
      var canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      var context = canvas.getContext("2d");
      context.drawImage(image, 0, 0, image.width, image.height);
      var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
    
      var a = document.createElement("a"); // 生成一个a元素
      var event = new MouseEvent("click"); // 创建一个单击事件
      a.download = name || "photo"; // 设置图片名称
      a.href = url; // 将生成的URL设置为a.href属性
      a.dispatchEvent(event); // 触发a的单击事件
    };
   return image.src = imgsrc;
  }
/**
 * 面积数字转换
 * @param {需要转化的数} num
 * @param {需要保留的小数位数} point
 */
export const areaFormat = (num, point = 2) => {
    if (isNaN(Number(num)) || num <= 0) return num
    const numStr = num.toString()
    const tem = numStr.split('.')
    const left = tem[0]
    if (left.length <= 6) {
        return num.toFixed(point) + '㎡'
    } else {
        const temp = num / 1000000
        return temp.toFixed(point) + 'k㎡'
    }
}

/**
 * promise async/await全局包装方法
 */
export const awaitWrap = (promise) => {
    return promise.then((data) => data).catch((err) => err)
}

/**
 * 指定字符串 溢出显示省略号
 * @param {String} str
 * @param {Number} num 保留字符数
 */
export const getSubStr = (str = '', num = 1) => {
    let newStr = ''
    if (str) {
        str = str + ''
        if (str.trim().length > num) {
            newStr = str.trim().substring(0, num) + '...'
        } else {
            newStr = str.trim()
        }
    }
    return newStr
}


/**
 * from lodash
 * @param {*} func
 * @param {*} wait
 * @param {*} options //loading: true
 */
export const debounce = (func, wait, options) => {
    let lastArgs, lastThis, maxWait, result, timerId, lastCallTime
    let lastInvokeTime = 0
    let leading = false
    let maxing = false
    let trailing = true

    const useRAF =
        !wait &&
        wait !== 0 &&
        typeof window.requestAnimationFrame === 'function'
    if (typeof func !== 'function') throw new TypeError('Expected a function')
    wait = +wait || 0
    if (isObj(options)) {
        leading = !!options.leading
        maxing = 'maxWait' in options
        maxWait = maxing ? Math.max(+options.maxWait || 0, wait) : maxWait
        trailing = 'trailing' in options ? !!options.trailing : trailing
    }

    function invokeFunc(time) {
        const args = lastArgs
        const thisArg = lastThis
        lastArgs = lastThis = undefined
        lastInvokeTime = time
        result = func.apply(thisArg, args)
        return result
    }

    function startTimer(pendingFunc, wait) {
        if (useRAF) {
            window.cancelAnimationFrame(timerId)
            return window.requestAnimationFrame(pendingFunc)
        }
        return setTimeout(pendingFunc, wait)
    }

    function cancelTimer(id) {
        if (useRAF) {
            return window.cancelAnimationFrame(id)
        }
        clearTimeout(id)
    }

    function leadingEdge(time) {
        lastInvokeTime = time
        timerId = startTimer(timerExpired, wait)
        return leading ? invokeFunc(time) : result
    }

    function remainingWait(time) {
        const timeSinceLastCall = time - lastCallTime
        const timeSinceLastInvoke = time - lastInvokeTime
        const timeWaiting = wait - timeSinceLastCall
        return maxing
            ? Math.min(timeWaiting, maxWait - timeSinceLastInvoke)
            : timeWaiting
    }

    function shouldInvoke(time) {
        const timeSinceLastCall = time - lastCallTime
        const timeSinceLastInvoke = time - lastInvokeTime
        return (
            lastCallTime === undefined ||
            timeSinceLastCall >= wait ||
            timeSinceLastCall < 0 ||
            (maxing && timeSinceLastInvoke >= maxWait)
        )
    }

    function timerExpired() {
        const time = Date.now()
        if (shouldInvoke(time)) return trailingEdge(time)
        timerId = startTimer(timerExpired, remainingWait(time))
    }

    function trailingEdge(time) {
        timerId = undefined
        if (trailing && lastArgs) return invokeFunc(time)
        lastArgs = lastThis = undefined
        return result
    }

    function cancel() {
        if (timerId !== undefined) cancelTimer(timerId)
        lastInvokeTime = 0
        lastArgs = lastCallTime = lastThis = timerId = undefined
    }

    function flush() {
        return timerId === undefined ? result : trailingEdge(Date.now())
    }

    function pending() {
        return timerId !== undefined
    }

    function debounced(...args) {
        const time = Date.now()
        const isInvoking = shouldInvoke(time)
        lastArgs = args
        lastThis = this
        lastCallTime = time

        if (isInvoking) {
            if (timerId === undefined) {
                return leadingEdge(lastCallTime)
            }
            if (maxing) {
                // Handle invocations in a tight loop.
                timerId = startTimer(timerExpired, wait)
                return invokeFunc(lastCallTime)
            }
        }
        if (timerId === undefined) {
            timerId = startTimer(timerExpired, wait)
        }
        return result
    }
    debounced.cancel = cancel
    debounced.flush = flush
    debounced.pending = pending
    return debounced
}

export const throttle = (func, wait, options) => {
    let leading = true
    let trailing = true

    if (typeof func !== 'function') {
        throw new TypeError('Expected a function')
    }
    if (isObj(options)) {
        leading = 'leading' in options ? !!options.leading : leading
        trailing = 'trailing' in options ? !!options.trailing : trailing
    }
    return debounce(func, wait, {
        leading,
        trailing,
        maxWait: wait,
    })
}

/**
 *
 * @param {*} file
 * @param {*} onError
 * @param {*} onProgress
 * @param {*} onSuccess
 */
export const uploadFileFn = (
    file,
    id,
    type,
    onError,
    onProgress,
    onSuccess
) => {
    let baseURL = process.env.VUE_APP_BASE_API
    if (!baseURL) baseURL = window.url
    const options = {
        endpoint: `${baseURL}/jxw/files/`,
        headers: {
            'Upload-ID': '_' + Math.random().toString(36).substr(2, 9),
            Authorization: `Bearer ${getToken()}`,
        },
        chunkSize: 500 * 1024 * 1024,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
            filename: file.name,
            par_dir: id,
            type: type,
        },
        onError,
        onProgress,
        onSuccess,
    }
    return new tus.Upload(file, options)
}
