import axios from 'axios'
import { Message } from 'element-ui';
import router from '@/router'
import { getToken, clearAllCookie } from '../utils/util'
class HttpRequest {
    constructor(options) {
        // console.log(options,"options");
        this.options = options
        this.queue = {}
        this.deley = 3  //请求的次数
    }

    getInsideConfig() {
        const config = {
            baseURL: this.options.baseURL || '/',
            timeout: this.options.timeout || 20000,
            isToken: true,
            isTip: true,//提示 true 全局提示
            isCircle: true, //是否多次请求
            ...this.options
        }
        return config
    }

    destroy(url) {
        delete this.queue[url]
    }

    interceptors(instance, options) {
        //  拦截请求 --带token
        instance.interceptors.request.use(
            config => {
                this.queue[options.url] = true
                if (options.isToken) {
                    const token = getToken()
                    if (token) config.headers.token = token
                }
                return config
            },
            error => {
                return Promise.reject(error)
            }
        )
            // 响应
        instance.interceptors.response.use(
            res => {
              this.destroy(options.url)
            if (res.headers['content-type'] === 'application/json'||res.headers['content-type'] == 'application/json;charset=utf-8') {
                    const { data } = res
                    if (options.isTip && data && data.code != 200){
                         Message.error(data.msg);
                         if (data.code==401) {
                            router.push('/login') 
                          }
                          if (res.data.code==320015) {
                            router.push('/404') 
                          }
                        return 
                    }
                    return data
                }
                 if (res.data.code==401) {
                    Message.error(res.data.msg);
                    router.push('/login') 
                  }
                  if (res.data.code==320015) {
                    Message.error(res.data.msg);
                    router.push('/404') 
                  }
                 return res
            },
            async error => {
                const config = error.config
                if (!config) return Promise.reject(error)
                config.__retryCount = config.__retryCount || 0
                if (error && error.response) {
                    const msg = error.response.data && error.response.data.msg
                    switch (error.response.status) {
                        case 400:
                            error.message = msg || 'Bad request'
                            break
                        case 401:
                           error.message =
                                msg || 'Token is invalid, please login'
                            clearAllCookie()
                            router.push('/login')
                            return Promise.reject(error.message)
                        case 403:
                            error.message = msg || 'Access denied'
                            return Promise.reject(error.message)
                        case 404:
                            error.message = '请求错误,未找到该资源'
                            break
                        case 405:
                            error.message =
                                msg ||
                                'Request error, the resource was not found'
                            break
                        case 408:
                            error.message = msg || 'Request timed out'
                            break
                        default:
                            error.message = `connection error:${error.response.status}`
                    }
                    if (error.response.status >= 500) {
                        router.push('/500')
                        return Promise.reject(error.message)
                    }
                } else {
                    error.message = '远程服务连接异常，请检查网络状况'
                    return Promise.reject(error.message)
                }
                if (!config.isCircle || config.__retryCount >= this.deley) {
                    this.destroy(options.url)
                    Message({
                        showClose: true,
                        message:"错误",
                        type: 'error'
                      });
                    return Promise.reject(error.message)
                }
                config.__retryCount += 1
                return new Promise(resolve =>
                    setTimeout(() => {
                        resolve(instance(config))
                    }, 1000)
                )
            }
        )
    }

    request(options) {
        const instance = axios.create()
        options = Object.assign(this.getInsideConfig(), options)
        // console.log(options,"98");
        this.interceptors(instance, options)
        return instance(options).catch(err => {
            console.error(err)
        })
    }
}
export default HttpRequest
