export default {
  namespaced: true,
  state: {
    multipleAccounts: [1,2],//登录后的多账号
    name: '',//当前商户名
    partyName: '',//当前企业
    idarr:[],
    password:"",//密码
  },
  mutations: {
   setmultipleAccounts(state, all) {
      state.multipleAccounts = all
    },
    updateName (state, name) {
      state.name = name
    },
    updatePartyName (state, name) {
      state.partyName = name
    },
    updateIdarr (state, name) {
      state.idarr = name
    },
    updatePassword (state, name) {
      state.password = name
    }
  }
}
