import axios from '@/utils/http'

export const login = ({ username, password, uuid, captcha }) => {
    return axios.request({
        url: '/biz/stateless/login',
        data: {
            username,
            password,
            uuid,
            captcha
        },
        method: 'post',
        isTip: false,//提示 true 全局提示
        isToken: false,
        isCircle: false, //是否多次请求
    })
}
// 获取token
export const gettokens = ({ userId, password}) => {
    return axios.request({
        url: '/biz/stateless/get-token',
        params: {
            userId,
            password,
        },
        method: 'post',
        isTip: false,//提示 true 全局提示
        isToken: false,
        isCircle: false, //是否多次请求
    })
}
// 修改密码的发送验证码
export const resetpw = ({mobile, uuid, captcha}) => {
    return axios.request({
        url: '/biz/stateless/sendSms-resetPw',
        data:{
            mobile,
            uuid,
            captcha,
        },
        method: 'post',
        isToken: false,
        isTip: false,
        isCircle: false,
    })
}
// 修改密码的验证短信验证码
export const checkCode = ({mobile, vcode}) => {
    return axios.request({
        url: '/biz/stateless/check-sms-code',
        data:{
            mobile,
            vcode
        },
        method: 'post',
        isToken: false,
        isTip: false,
        isCircle: false,
    })
}
// 获取userId用户手机号
export const userPhone = ({userId}) => {
    return axios.request({
        url: '/biz/stateless/userInfo',
        params:{userId},
        method: 'get',
        isToken: false,
        isTip: true,
        isCircle: false,
    })
}
// 通过account获取账户手机号
export const userInfoAccount = ({account}) => {
    return axios.request({
        url: '/biz/stateless/user-mobile',
        params:{account},
        method: 'get',
        isToken: false,
        isTip: false,
        isCircle: false,
    })
}
// 设置新密码(未登录)
export const newpPssword = ({newPassword,mobile, vcode,partyId}) => {
    return axios.request({
        url: '/biz/stateless/modify-password-mobile',
        data:{
            newPassword,
            mobile,
            vcode,
            partyId
        },
        method: 'post',
        isToken: false,
        isTip: true,
        isCircle: false,
    })
}
// 设置新密码(已经登录)
export const resetPassword = (data) => {
    return axios.request({
        url: '/user/reset-password-sign-in',
        data:data,
        method: 'post',
        isToken: true,
        isTip: true,
        isCircle: false,
    })
}
// 目录
export const nav = ({}) => {
    return axios.request({
        url: '/menu/nav',
        params: {},
        method: 'get',
        isToken: true,
        isTip: true,
        isCircle: false,
    })
}

// 退出
export const logout = ({}) => {
    return axios.request({
        url: '/biz/stateless/logout',
        data:{},
        method: 'post',
        isToken: true,
        isTip: true,
        isCircle: false,
    })
}
// 获取登录用户信息
export const info = ({}) => {
    return axios.request({
        url: '/user/userInfo',
        params:{},
        method: 'get',
        isToken: true,
        isTip: false,
        isCircle: false,
    })
}

export const menulist = ({}) => {
    return axios.request({
        url: '/sys/menu/list',
        params: {},
        method: 'get',
        isToken: true,
        isTip: true,
        isCircle: false,
    })
}
