<template>
  <div id="app">
      <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="less">
.size {
    width: 100%;
    // height: 100%;
}
html,
body {
    margin: 0;
    padding: 0;
}
#app {
    margin: 0;
    padding: 0; 
    // font-size: 12px;
}
</style>
